<template>
  <div class="bodyHome">
    <div style="width: 100%;margin-top: var(--200);" class="font-size32 font-color1B1B1B font-weightBold margin-left60">
      检查站点连接是否安全
    </div>
    <Vcode :show="isShow" @success="onSuccess" @close="onClose"/>
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";

export default {
  components: {
    Vcode,
  },
  data() {
    return {
      isShow: true,
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "验证"
    } catch (error) {
      console.log("常规错误:" + error)
    }
  },
  methods: {
    onSuccess(msg) {
      console.log(msg)
      this.isShow = false; // 通过验证后，需要手动关闭模态框
      this.$router.replace({
        path: '/homePage',
      });
    },
    onClose() {
      // this.isShow = false;
    },
  }
}
</script>

<style scoped>
.bodyHome {
  background-color: #F7F7F9;
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
